import { config } from '../app-configuration/config.js';

/**
 * Get the firebase config for an app.
 * @param {string} appId - The app ID (project ID) to get the firebase config for.
 * @returns {import('../../../config.js').FirebaseConfig | {}} The firebase config (or `{}` if no config is found for the app ID).
 */
export function FirebaseConfig(appId) {
  const { firebaseConfigs } = config;
  const firebaseConfig = firebaseConfigs[appId];
  return firebaseConfig
    ? {
        apiKey: firebaseConfig.apiKey,
        authDomain: firebaseConfig.authDomain,
        projectId: firebaseConfig.projectId,
        databaseURL: firebaseConfig.databaseURL,
        storageBucket: firebaseConfig.storageBucket,
        messagingSenderId: firebaseConfig.messagingSenderId,
        appId: firebaseConfig.appId
      }
    : {};
}
